<script lang="ts">
  import {} from "../../tools/LayoutTools";
  import { drawState, drawSend } from "../../layout.store";
  import Icon from "../base/Icon.svelte";
  import Delete from "../icons/Delete.svelte";
  import RotateRight from "../icons/RotateRight.svelte";
  import RotateRightRepeat from "../icons/RotateRightRepeat.svelte";
  import { currentUser } from "src/store";

  let isRotationEnabled = true;
  let isRepeatRotation = true;
  let isAdmin = false;
  let isProto = false;
  let lockedWidth = true;
  let lockedHeight = true;
  let shapeIndex = undefined;

  $: centerX = 0;
  $: centerY = 0;
  $: selectedTileID = $drawState.context.layoutContext.selectedTileID;

  $: {
    centerX = 0;
    centerY = 0;
    if (selectedTileID !== undefined)
    {
      const tileElement = document.getElementById(`tile_${selectedTileID}`);
      if (tileElement) {
        const rect = tileElement.getBoundingClientRect();
        const scrollLeft =
          window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const elRect = {
          top: rect.top + scrollTop,
          left: rect.left + scrollLeft,
          width: rect.right - rect.left,
          height: rect.bottom - rect.top,
        };
        centerX = elRect.left + elRect.width / 2;
        centerY = elRect.top;
      }

      const layout = $drawState.context.layoutContext.layout;
      const tile = layout.tiles.get(selectedTileID);
      if (tile)
      {
        const isFirstRepeatChild = layout.isRepeatShape(tile);
        // root cannot be rotated
        // isRotationEnabled = !layout.isRoot(tile) || isFirstRepeatChild;
        isRepeatRotation = isFirstRepeatChild;
        isProto = tile.depth === 0;
        isAdmin = $currentUser?.is_admin === 1;

        const shapes = layout.shapes;
        shapeIndex = shapes.findIndex(s => tile.shape === s);
        if (shapeIndex >= 0)
        {
          lockedWidth = layout.lockedDimensions[shapeIndex][0] === 1;
          lockedHeight = layout.lockedDimensions[shapeIndex][1] === 1;
        }
      }
    }
  }

  function onRotate() {
    if (selectedTileID !== undefined) {
      drawSend({ type: "ROTATE_TILE", tileID: selectedTileID });
    }
  }

  function onRemove() {
    if (selectedTileID !== undefined) {
      drawSend({ type: "REMOVE_TILE", tileID: selectedTileID });
    }
  }

  function handleWidth() {
    if (selectedTileID !== undefined && shapeIndex !== undefined)
      drawSend({ type: !lockedWidth ? "LOCK_TILE_WIDTH" : "UNLOCK_TILE_WIDTH", shapeIndex: shapeIndex });
  }

  function handleHeight() {
    if (selectedTileID !== undefined && shapeIndex !== undefined)
      drawSend({ type: !lockedHeight ? "LOCK_TILE_HEIGHT" : "UNLOCK_TILE_HEIGHT", shapeIndex: shapeIndex });
  }
  // if there ever will be more than 2 buttons, button refactoring to separate component should be considered
</script>

{#if selectedTileID !== undefined}
  <div
    class="absolute flex gap-x-1.5 p-2 bg-gray-200 shadow opacity-60 border border-solid border-gray-400 rounded transition-opacity hover:opacity-100 focus-within:opacity-100 translate-x-tileFloatingMenu-x translate-y-tileFloatingMenu-y"
    style="left: {centerX}px; top: {centerY}px"
  >
    <button
      class="flex-1 inline-flex items-center p-1 border border-transparent rounded shadow text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 disabled:opacity-40 disabled:border-transparent"
      on:click={onRotate}
      disabled={!isRotationEnabled}
    >
      <Icon width="w-7" height="h-7" icon={isRepeatRotation ? 'rotate-right-repeat' : 'rotate-right'} />
    </button>
    <button
      class="flex-1 inline-flex items-center p-1 border border-transparent rounded shadow text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 disabled:opacity-40 disabled:border-transparent"
      on:click={onRemove}
    >
      <Icon width="w-7" height="h-7" icon="delete" />
    </button>
    {#if isAdmin && isProto}
      <button
        class="flex-1 inline-flex items-center p-1 border border-transparent rounded shadow text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 disabled:opacity-40 disabled:border-transparent"
        on:click={handleWidth}
      >
        <i class="w-7 fa-light {lockedWidth ? 'fa-lock-open' : 'fa-w'} fa-custom-lg"/>
      </button>
      <button
        class="flex-1 inline-flex items-center p-1 border border-transparent rounded shadow text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 disabled:opacity-40 disabled:border-transparent"
        on:click={handleHeight}
      >
        <i class="w-7 fa-light {lockedHeight ? 'fa-lock-open' : 'fa-h'} fa-custom-lg"/>
      </button>
    {/if}
  </div>
{/if}
