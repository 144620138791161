<script lang="ts">
  import { drawState, drawSend } from "../layout.store";
  import TileAddButtonsLayer from "./layers/TileAddButtonsLayer.svelte";
  import SnapPointsLayer from "./layers/SnapPointsLayer.svelte";
  import BaseShapes from "./BaseShapes.svelte";
  import TileConnectButtonsLayer from "./layers/TileConnectButtonsLayer.svelte";
  import TileDebugLayer from "./layers/TileDebugLayer.svelte";
  import ConfirmAutoLayoutRepeat from "./layers/ConfirmAutoLayoutRepeat.svelte";
  import RemoveTileDialog from "./dialogs/RemoveTileDialog.svelte";
  import TileFloatingMenu from "./dialogs/TileFloatingMenu.svelte";
  import SaveLayoutGeometry from "./dialogs/SaveLayoutGeometry.svelte";
  import LayoutTile from "./tiles/LayoutTile.svelte";

  import BoundingBox from "../model/tile/BoundingBox";
  import Vector2 from "../model/tile/Vector2";
  import {
    getNonPrototileTilesWithIDs,
    isNonSingleTileLayout,
    limitLayoutTileDepthForDrag,
  } from "../tools/LayoutTools";
  import PassiveTile from "./tiles/PassiveTile.svelte";
  import { _ } from "../services/i18n";
  import HelperLayer from "./layers/HelperLayer.svelte";
    import TileAnchorPointsMenu from "./dialogs/TileAnchorPointsMenu.svelte";
  import SelectTile from "./toolbox/SubPanels/SelectTile.svelte";
    
  $: isRootSelection = $drawState.matches("layoutDesign.noLayout.showAddRootTile");
  $: isRegularSelection = $drawState.matches("layoutDesign.showLayout.layoutState.showAddTileSelection");
  $: showTileSelect = isRootSelection || isRegularSelection;

  let layout = $drawState.context.layoutContext.layout;
  $: layoutBBox = new BoundingBox(
    new Vector2(-layout.width / 2, -layout.height / 2),
    new Vector2(layout.width / 2, layout.height / 2)
  );

  $: shouldRenderLayout =
    $drawState.matches("layoutDesign.showLayout") &&
    $drawState.context.layoutContext.layout;

  // limits layout rendering size during tile dragging
  $: tilesToRender = limitLayoutTileDepthForDrag(
    $drawState.context.layoutContext.layout,
    $drawState.matches("layoutDesign.showLayout.selectionState.dragging")
  );
  $: autoRepeatPreviewTiles = getNonPrototileTilesWithIDs(
    $drawState.context.layoutContext.autoRepeatPreviewLayout
  );
  $: shouldRenderAutoRepeatPreview =
    $drawState.matches("layoutDesign.showLayout.layoutState.idle") &&
    $drawState.matches("layoutDesign.showLayout.selectionState.noSelection") &&
    $drawState.matches(
      "layoutDesign.showLayout.autoRepeatState.resolvedAutoRepeat.repeatPreview"
    ) &&
    isNonSingleTileLayout($drawState.context.layoutContext.layout) &&
    autoRepeatPreviewTiles.length !== 0;

  $: isTileSelected = $drawState.matches(
    "layoutDesign.showLayout.selectionState.selection"
  );

  $: baseShapeDefs = $drawState.context.layoutContext.baseShapes.filter(
    (bs) => bs.tileData.images
  );
  // combined with |self modifier in definition prevents on unselecting tile when mouseup event happened
  // outside of the tile because tile could not moved due to move area/path boundaries
  // https://stackoverflow.com/questions/34029785/what-is-the-correct-w3c-click-event-specification?rq=1
  let isClickSource = false;

  // TODO
  // somehow tell the user that the specified tile cannot be placed because it can't fit

  // workaround for warning that types don't work in template
  function onKeyPressed(event: KeyboardEvent) {
    drawSend(event as KeyboardEvent & { type: "keyup" });
  }

  // const layoutTile = () => {
  //   drawSend({ type: "SAVE_TILE_LAYOUT", tileData: $drawState.context.layout });
  // };

  const closeTileEdit = () => {
    drawSend({ type: "CLOSE_TILE_LAYOUT" });
  };
</script>

<svelte:window on:keyup={onKeyPressed} />

<div id="layout-designer-area" class="relative">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="-6 -6 12 12"
    preserveAspectRatio="xMidYMid meet"
    width="100%"
    height="100%"
    focusable="true"
    on:mousedown|self={() => (isClickSource = true)}
    on:touchstart|self={() => (isClickSource = true)}
    on:click|self={() => {
      if (showTileSelect) drawSend("CANCEL_TILE_SELECTION");
      if (isClickSource) drawSend({ type: "DESELECT_TILE" });
      isClickSource = false;
    }}
    on:dblclick={() => drawSend({ type: "AUTO_REPEAT_LAYOUT" })}
  >
    <defs>
      <clipPath id="clip-bounds">
        <rect
          x={layoutBBox.min.x}
          y={layoutBBox.min.y}
          width={layoutBBox.size.x}
          height={layoutBBox.size.y}
        />
      </clipPath>
      {#each baseShapeDefs as bs (bs.tileData.filterId)}
        <!-- {#each bs.tileData.images as image, index (image)}
          <filter id={`${bs.tileData.filterId}-${index}`}>
            <feImage xlink:href={image} preserveAspectRatio="xMidYMid slice" />
          </filter>
        {/each} -->
        <!-- {#each bs.tileData.images as image, index (image)}
          <pattern
            id={`${bs.tileData.filterId}-${index}`}
            patternUnits="objectBoundingBox"
            x="0"
            y="0"
            width={bs.boundingBox.size.x}
            height={bs.boundingBox.size.y}
          >
            <image
              href={image}
              width={bs.boundingBox.size.x}
              height={bs.boundingBox.size.y}
              preserveAspectRatio="none"
            />
          </pattern>
        {/each} -->
      {/each}
    </defs>
    <g style="clip-path: url('#clip-bounds');">
      {#if shouldRenderAutoRepeatPreview}
        {#each autoRepeatPreviewTiles as [tile, idx]}
          <PassiveTile
            shape={tile.shape}
            transform={tile.transform}
            tileID={idx}
            opacity={0.33}
          />
        {/each}
      {/if}
      {#if shouldRenderLayout}
        {#each tilesToRender as tile, i}
          <LayoutTile
            shape={tile.shape}
            transform={tile.transform}
            depth={tile.depth}
            tileID={i}
          />
        {/each}
      {/if}
      {#if $drawState.matches("layoutDesign.showLayout.selectionState.dragging")}
        <HelperLayer />
      {:else}
        <TileAddButtonsLayer />
      {/if}
      <SnapPointsLayer />
      <TileConnectButtonsLayer />
      <TileDebugLayer />
    </g>
  </svg>
  {#if isTileSelected}
    <TileFloatingMenu />
    <TileAnchorPointsMenu />
  {/if}
  {#if shouldRenderAutoRepeatPreview}
    <ConfirmAutoLayoutRepeat />
  {/if}
  <button
    class="close-btn uppercase secondary_action_button"
    on:click={closeTileEdit}>{$_("tile.close")}</button
  >
  <!-- <BaseShapes /> -->
  {#if showTileSelect}
  <div class="fixed left-0 top-0 bottom-0 p-2 bg-white border border-r-gray-300 z-20">
    <SelectTile tileWrapper={undefined} />
  </div>
  {/if}
  <RemoveTileDialog />
  <SaveLayoutGeometry {tilesToRender} />
  <!-- <button class="ok-btn uppercase secondary_action_button" on:click={layoutTile}
    >{$_("tile.ok")}</button
  > -->
</div>

<style lang="scss">
  #layout-designer-area {
    height: 100%;
  }
  // .ok-btn {
  //   padding: 8px 16px;
  //   display: flex;
  //   min-width: 160px;
  //   justify-content: center;
  //   position: absolute;
  //   left: 40px;
  //   bottom: 90px;
  //   border-radius: 8px;
  // }
  .close-btn {
    padding: 8px 16px;
    display: flex;
    min-width: 160px;
    justify-content: center;
    position: absolute;
    left: 40px;
    bottom: 40px;
    border-radius: 8px;
  }
</style>
