<svelte:options immutable={true} />

<script lang="ts">
  import { ANCHOR_POINTS } from "src/model/tile/EdgeReference";
  import { drawService, drawState, drawSend } from "../../layout.store";
  import { currentUser } from "src/store";
  import Select from "../base/Select.svelte";
  import { _ } from "src/services/i18n";
  import { useSelector } from "@xstate/svelte";
  import type { NeighborsType } from "src/model/tile/Layout";
  import type { NeighborType } from "src/model/tile/Neighbor";

  let isRotationEnabled = true;
  let isRepeatRotation = true;
  let isAdmin = false;
  let isProto = false;
  let shapeIndex = undefined;
  let parents: { label: string, value: NeighborType }[] = [];
  let children: { label: string, value: NeighborType }[] = [];
  let selectedParent: NeighborType = undefined;
  let selectedChild: NeighborType = undefined;
  let selectedParentIndex: number = undefined;
  let selectedChildIndex: number = undefined;
  let selectedParentAnchorPoint: { source: ANCHOR_POINTS, target: ANCHOR_POINTS } = undefined;
  let selectedChildAnchorPoint: { source: ANCHOR_POINTS, target: ANCHOR_POINTS } = undefined;

  const anchorPoints = [
    { id: ANCHOR_POINTS.LEFT,   name: "Left" },
    { id: ANCHOR_POINTS.CENTER, name: "Center" },
    { id: ANCHOR_POINTS.RIGHT,  name: "Right" },
  ];

  const selectedTileID = useSelector(drawService, (state) => state.context.layoutContext.selectedTileID);
  $: $selectedTileID, reactiveselectedTileID()
  function reactiveselectedTileID() {

    parents = [];
    children = [];
    selectedParent = undefined;
    selectedChild = undefined;
    selectedParentIndex = undefined;
    selectedChildIndex = undefined;
    selectedParentAnchorPoint = undefined;
    selectedChildAnchorPoint = undefined;

    if ($selectedTileID !== undefined)
    {
      const layout = $drawState.context.layoutContext.layout;
      const tile = layout.tiles.get($selectedTileID);
      if (tile)
      {
        const isFirstRepeatChild = layout.isRepeatShape(tile);
        // root cannot be rotated
        // isRotationEnabled = !layout.isRoot(tile) || isFirstRepeatChild;
        isRepeatRotation = isFirstRepeatChild;
        isProto = tile.depth === 0;
        isAdmin = $currentUser?.is_admin === 1;

        const shapes = layout.shapes;
        shapeIndex = shapes.findIndex(s => tile.shape === s);        
        
        if (shapeIndex >= 0)
        {
          const graph = layout.getGraph();
          const neighbors = graph.svg_path.neighbors[shapeIndex];

          parents = neighbors.parents.map((value, index) => (
            // { label: $_(`tile.sort.${index}`), value}
            { label: $_(`Parent ${index}`), value}
          ))

          if (parents.length > 0)
          {
            selectedParentIndex = 0;
            selectedParent = parents[selectedParentIndex].value;
          }

          children = neighbors.children.map((value, index) => (
            // { label: $_(`tile.sort.${index}`), value}
            { label: $_(`Child ${index}`), value}
          ))

          if (children.length > 0)
          {
            selectedChildIndex = 0;
            selectedChild = children[selectedChildIndex].value;
          }
        }
      }
    }
  }

  $: {
    if (selectedParent)
    {
      updateAnchorPoints(false);
    }
  }

  $: {
    if (selectedChild)
    {
      updateAnchorPoints(true);
    }
  }

  function updateAnchorPoints(isChild: boolean) {

    if (isChild)
    {
      selectedChildAnchorPoint = {
        source: selectedChild.source.anchorPoint,
        target: selectedChild.target.anchorPoint
      }
    }
    else
    {
      selectedParentAnchorPoint = {
        source: selectedParent.source.anchorPoint,
        target: selectedParent.target.anchorPoint
      }
    }
  }

  function changeAnchorPoint(sourceAnchorPoint: ANCHOR_POINTS, targetAnchorPoint: ANCHOR_POINTS,
                             sourceShapeIndex: number, targetShapeIndex: number,
                             targetIsChild: boolean) {

    drawSend({ type: "CHANGE_ANCHOR_POINT",
      changeAnchorPoint: {
        source: { anchorPoint: sourceAnchorPoint, shapeIndex: sourceShapeIndex },
        target: { anchorPoint: targetAnchorPoint, shapeIndex: targetShapeIndex },
        targetIsChild: targetIsChild
      }
    });

    const layout = $drawState.context.layoutContext.layout;
    const graph = layout.getGraph();
    const neighbors = graph.svg_path.neighbors[shapeIndex];

    parents = neighbors.parents.map((value, index) => (
      // { label: $_(`tile.sort.${index}`), value}
      { label: $_(`Parent ${index}`), value}
    ))

    if (parents.length > 0)
      selectedParent = parents[selectedParentIndex].value;

    children = neighbors.children.map((value, index) => (
      // { label: $_(`tile.sort.${index}`), value}
      { label: $_(`Child ${index}`), value}
    ))

    if (children.length > 0)
      selectedChild = children[selectedChildIndex].value;
  }
</script>

{#if selectedTileID !== undefined}
  <div
    class="divide-y divide-gray-600 w-44 absolute flex flex-col gap-1.5 p-2 bg-gray-200 shadow opacity-60 border border-solid border-gray-400 rounded
           transition-opacity hover:opacity-100 focus-within:opacity-100 transform translate-x-tileFloatingMenu-x translate-y-tileFloatingMenu-y"
    style="top: 20px; right: 50px"
  >
    <div class="text-lg font-bold">
      <!-- {$_("side_menu.layout.change_tile")} -->
      Anchor points:
    </div>
    <!-- <div>
      {#if parents.length === 0}
        <div class="text-base">
          No Parents
        </div>
      {:else}
        <div class="text-base">
          Parents
        </div>
        <Select options={parents} bind:value={selectedParent} bind:index={selectedParentIndex} inputProps="w-full"/>
        {#each anchorPoints as anchor}
          <div class="flex flex-wrap gap-1.5">
            <div class="text-sm w-full">
              {anchor.name}
            </div>
            <button
              class="items-center p-1 border border-transparent rounded shadow disabled:opacity-40 disabled:border-transparent
                     {selectedParentAnchorPoint.source === anchor.id && selectedParentAnchorPoint.target === ANCHOR_POINTS.LEFT ?
                     'text-gray-100 bg-gray-700' : 'text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow'}"
                     on:click={() => changeAnchorPoint(anchor.id, ANCHOR_POINTS.LEFT, shapeIndex, selectedParent.target.shapeIndex, false)}
            >
              <i class="w-7 fa-light fa-left"/>
            </button>
            <button
              class="items-center p-1 border border-transparent rounded shadow disabled:opacity-40 disabled:border-transparent
                     {selectedParentAnchorPoint.source === anchor.id && selectedParentAnchorPoint.target === ANCHOR_POINTS.CENTER ?
                     'text-gray-100 bg-gray-700' : 'text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow'}"
              on:click={() => changeAnchorPoint(anchor.id, ANCHOR_POINTS.CENTER, shapeIndex, selectedParent.target.shapeIndex, false)}
            >
              <i class="w-7 text-base fa-light fa-circle"/>
            </button>
            <button
              class="items-center p-1 border border-transparent rounded shadow disabled:opacity-40 disabled:border-transparent
                     {selectedParentAnchorPoint.source === anchor.id && selectedParentAnchorPoint.target === ANCHOR_POINTS.RIGHT ?
                     'text-gray-100 bg-gray-700' : 'text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow'}"
              on:click={() => changeAnchorPoint(anchor.id, ANCHOR_POINTS.RIGHT, shapeIndex, selectedParent.target.shapeIndex, false)}
            >
              <i class="w-7 fa-light fa-right"/>
            </button>
          </div>
        {/each}
      {/if}
    </div> -->
    <div>
      {#if children.length === 0}
        <div class="text-base">
          <!-- {$_("side_menu.layout.change_tile")} -->
          No Children
        </div>
      {:else}
        <div class="text-base">
          <!-- {$_("side_menu.layout.change_tile")} -->
          Children
        </div>
        <Select options={children} bind:value={selectedChild} bind:index={selectedChildIndex} inputProps="w-full"/>
        {#each anchorPoints as anchor}
          <div class="flex flex-wrap gap-1.5">
            <div class="text-sm w-full">
              <!-- {$_("side_menu.layout.change_tile")} -->
              {anchor.name}
            </div>
            <button
              class="items-center p-1 border border-transparent rounded shadow disabled:opacity-40 disabled:border-transparent
                     {selectedChildAnchorPoint.source === anchor.id && selectedChildAnchorPoint.target === ANCHOR_POINTS.LEFT ?
                     'text-gray-100 bg-gray-700' : 'text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow'}"
              on:click={() => changeAnchorPoint(anchor.id, ANCHOR_POINTS.LEFT, shapeIndex, selectedChild.target.shapeIndex, true)}
            >
              <i class="w-7 fa-light fa-left fa-custom-lg"/>
            </button>
            <button
              class="items-center p-1 border border-transparent rounded shadow disabled:opacity-40 disabled:border-transparent
                     {selectedChildAnchorPoint.source === anchor.id && selectedChildAnchorPoint.target === ANCHOR_POINTS.CENTER ?
                     'text-gray-100 bg-gray-700' : 'text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow'}"
              on:click={() => changeAnchorPoint(anchor.id, ANCHOR_POINTS.CENTER, shapeIndex, selectedChild.target.shapeIndex, true)}
            >
              <i class="w-7 text-base fa-light fa-circle fa-custom-lg"/>
            </button>
            <button
              class="items-center p-1 border border-transparent rounded shadow disabled:opacity-40 disabled:border-transparent
                     {selectedChildAnchorPoint.source === anchor.id && selectedChildAnchorPoint.target === ANCHOR_POINTS.RIGHT ?
                     'text-gray-100 bg-gray-700' : 'text-gray-700 bg-gray-100 hover:border-gray-400 hover:shadow'}"
              on:click={() => changeAnchorPoint(anchor.id, ANCHOR_POINTS.RIGHT, shapeIndex, selectedChild.target.shapeIndex, true)}
            >
              <i class="w-7 fa-light fa-right fa-custom-lg"/>
            </button>
          </div>
        {/each}
      {/if}
    </div>
  </div>
{/if}
