<script lang="ts">
  import { drawSend, drawState } from "../../layout.store";
  import Modal from "./Modal.svelte";
  import { _ } from "../../services/i18n";
  import type ShapeInstance from "src/model/tile/ShapeInstance";
  import { createLayoutGeometry, createTileLayout } from "src/services/api";
  import type { GeometryType } from "src/model/tile/RealizedLayout";
  import Loading from "../util/Loading.svelte";
  import { createPreviewLayout } from "src/helpers";
  import Input from "../base/Input.svelte";
  import Button from "../base/Button.svelte";

  let preview_image, preview_image_with_texture;
  let imageBase64: string | ArrayBuffer;
  let imageBase64WithTexture: string | ArrayBuffer;
  let name: string;
  let onCreating = false;
  let onSaving = false;
  let graph: GeometryType;
  let isSaveTileLayout = false;

  export let tilesToRender: ShapeInstance[];

  $: if (!!tilesToRender) {
    preview_image = undefined;
    preview_image_with_texture = undefined;
  }

  $: if (onCreating) {
    
    if (!isSaveTileLayout && !!preview_image) {
      URL.revokeObjectURL(preview_image);
    }
    if (isSaveTileLayout && !!preview_image_with_texture) {
      URL.revokeObjectURL(preview_image_with_texture);
    }

    createPreviewLayout(
      tilesToRender,
      $drawState.context.layoutContext.layout,
      $drawState.context.layoutContext.layout.shapes,
      isSaveTileLayout,
      undefined,
      true
    ).then(({ blob, base64 }) => {
      if (!isSaveTileLayout) preview_image = URL.createObjectURL(blob);
      else preview_image_with_texture = URL.createObjectURL(blob);

      if (isSaveTileLayout) imageBase64WithTexture = base64;
      else imageBase64 = base64;
      
      onCreating = false;
    })
  }

  // No need to save tile layout now
  // $: if (graph) {
  //   const baseShapeDefs = $state.context.baseShapes.filter(
  //     (bs) => bs.tileData.images
  //   );
  //   isSaveTileLayout = graph.tiles.some(
  //     (tile) => !!baseShapeDefs.find((bs) => bs.tileId === tile)
  //   );
  // }
</script>

<Modal containerClass="rounded-3xl w-[480px]">
  <div slot="trigger" let:open>
    {#if tilesToRender.length > 0}<button
        class="save-btn uppercase secondary_action_button"
        on:click={() => {
          if (!preview_image) onCreating = true;
          open();
        }}
      >
        {$_(`tile.ok`)}
      </button>
    {/if}
  </div>
  <div slot="header" let:close>
    {#if !onCreating}
      <div class="flex justify-between items-center gap-3 px-6 py-4">
        <div class="text-heading-4 font-bold text-title">{$_(`tile.save_layout`)}</div>
        <i
          class="fa-solid fa-xmark cursor-pointer fa-2x text-title"
          color={"black"}
          on:click={close}
          on:keydown
        />
      </div>
    {/if}
  </div>
  <div slot="content" class="px-3">
    {#if onCreating}
      <div class="px-2 py-1">Creating preview image...</div>
    {:else}
      <div class="flex items-center gap-3 mt-6">
        <div class="text-base font-bold">{$_(`tile.name`)}</div>
        <Input 
          value={name}
          on:input={(e) => { name = e.target.value }}
          fullWidth
        />
      </div>
      <div class="mt-6 text-base font-bold">{$_("tile.preview_image")}</div>
      <div class="my-6 flex justify-center px-20 sm:px-32">
        <!-- <object
          data={preview_image}
          class="preview-image"
          aria-label="preview_image"
        /> -->
        <img
          src={isSaveTileLayout ? preview_image_with_texture : preview_image}
          class="preview-image"
          alt="preview_image"
        />
      </div>
    {/if}
  </div>
  <div slot="footer" let:close>
    {#if !onCreating}
      <div class="flex justify-end items-center px-2 py-3">
        {#if onSaving}
          <Loading />
        {:else}
          <Button 
            variant="primary" 
            title={$_("project_wizard.create")} 
            class="w-[130px] justify-center"
            on:click={() => {
              onSaving = true;
              graph = $drawState.context.layoutContext.layout.getGraph();
              const jsonData = {
                name,
                svg_path: JSON.stringify(graph.svg_path),
                tile_shapes: graph.tile_shapes,
                num_shapes: graph.num_shapes,
                min_gap_size: graph.min_gap_size,
                preview_image: imageBase64.toString(),
              };
              createLayoutGeometry(jsonData)
                .then((res) => {
                  if (isSaveTileLayout) {
                    //Save tile layout
                    if (res.data?.layout_geometry?.id) {
                      createTileLayout({
                        layout_geometry: res.data?.layout_geometry.id,
                        tiles: graph.tiles,
                        preview_image: imageBase64WithTexture,
                      })
                        .then((_) => {
                          close();
                        })
                        .catch((_) => {});
                    } else {
                    }
                  } else {
                    const baseShapes = $drawState.context.layoutContext.baseShapes;
                    drawSend({
                      type: "ADD_LAYOUT_GEOMETRY",
                      layoutGeometry: {
                        ...jsonData,
                        id: res.data?.layout_geometry?.id,
                        tile_shapes: jsonData.tile_shapes.map((tileShapeId) => {
                          const baseShape = baseShapes.find((v) => v.shapeId === tileShapeId)
                          return {
                            id: tileShapeId,
                            name: baseShape.name,
                            svg_path: baseShape.path.toSvgPath(),
                            default_width: baseShape.width,
                            default_height: baseShape.height,
                            thumbnail: ''
                          }
                        })
                      }
                    })
                    drawSend({
                      type: "SAVE_TILE_LAYOUT",
                      tileData: $drawState.context.layoutContext.layout,
                      savedGeometryLayoutId: res.data?.layout_geometry?.id,
                    });
                    close();
                  }
                })
                .catch((_) => {})
                .finally(() => {
                  onSaving = false;
                });
            }}
            disabled={!imageBase64 || !tilesToRender.length || !name}
            roundedFull
          />
        {/if}
      </div>
    {/if}
  </div>
</Modal>

<style scoped lang="scss">
  .save-btn {
    padding: 8px 16px;
    display: flex;
    min-width: 160px;
    justify-content: center;
    position: absolute;
    left: 40px;
    bottom: 100px;
    border-radius: 8px;
  }
  .preview-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: fill;
  }
</style>
